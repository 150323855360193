import React, { useState, useEffect, Suspense } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';

// Core components that should load immediately
import Header from './components/Header';
import MainContent from './components/MainContent';
import AdvertBanner from './components/AdvertBanner';
import AnnouncementBar from './components/AnnouncementBar';
import LanguageRouter from './components/LanguageRouter';

// Lazy loaded components
import { BlogList, BlogPost, About, Prices, Testimonials } from './utils/lazyComponents';

// Translation imports - these are small and can be loaded immediately
import { english } from './translations/english';
import { georgian } from './translations/georgian';
import { russian } from './translations/russian';

// Analytics utility
const Analytics = {
  init: async () => {
    if (process.env.NODE_ENV === 'production') {
      const [
        { trackLanguageChange, usePageTracking },
        { initializeMetaPixel, trackPageView }
      ] = await Promise.all([
        import('./utils/analytics'),
        import('./utils/metaPixel')
      ]);

      return {
        trackLanguageChange,
        usePageTracking,
        initializeMetaPixel,
        trackPageView
      };
    }
    return {
      trackLanguageChange: () => { },
      usePageTracking: () => { },
      initializeMetaPixel: () => { },
      trackPageView: () => { }
    };
  }
};

// Lazy load TawkTo
const TawkTo = React.lazy(() => import('./components/TawkTo'));

// Route change tracker component
const RouteChangeTracker = () => {
  const location = useLocation();

  useEffect(() => {
    if (window.analytics) {
      window.analytics.trackPageView();
    }
  }, [location]);

  return null;
};

const App = () => {
  const [language, setLanguage] = useState(() => {
    const savedLanguage = localStorage.getItem('preferredLanguage');
    return savedLanguage || 'english';
  });

  const [analytics, setAnalytics] = useState(null);
  const [isTawkToVisible, setIsTawkToVisible] = useState(false);

  useEffect(() => {
    // Initialize analytics
    Analytics.init().then(setAnalytics);

    // Add scroll listener for TawkTo
    const handleScroll = () => {
      const scrolled = window.scrollY;
      const viewportHeight = window.innerHeight;
      const totalHeight = document.documentElement.scrollHeight;

      if ((scrolled / (totalHeight - viewportHeight)) > 0.25) {
        setIsTawkToVisible(true);
        window.removeEventListener('scroll', handleScroll);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const handleLanguageChange = (newLanguage) => {
    setLanguage(newLanguage);
    localStorage.setItem('preferredLanguage', newLanguage);
    analytics?.trackLanguageChange(newLanguage);

    const languageUrls = {
      'english': 'en',
      'georgian': 'ka',
      'russian': 'ru'
    };

    const currentPath = window.location.pathname;
    const langPrefix = languageUrls[newLanguage];
    const pathWithoutLang = currentPath.replace(/^\/(?:en|ka|ru)/, '');
    const newPath = `/${langPrefix}${pathWithoutLang || ''}`;
    window.history.replaceState(null, '', newPath);
  };

  const t = language === 'english' ? english :
    language === 'georgian' ? georgian : russian;

  const getDefaultLanguageCode = () => {
    return language === 'english' ? 'en' :
      language === 'georgian' ? 'ka' : 'ru';
  };

  return (
    <HelmetProvider>
      <div>
        <AnnouncementBar />
        <Router>
          <LanguageRouter language={language} setLanguage={setLanguage}>
            <RouteChangeTracker />
            <div className="min-h-screen flex flex-col pt-11 md:pt-9">
              <Header language={language} setLanguage={handleLanguageChange} />
              <div className="flex-grow bg-gray-50">
                <Routes>
                  {/* Root redirect */}
                  <Route
                    path="/"
                    element={<Navigate to={`/${getDefaultLanguageCode()}`} replace />}
                  />

                  {/* Main routes with language parameter */}
                  <Route
                    path="/:lang"
                    element={
                      <>
                        <AdvertBanner />
                        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 -mt-4">
                          <MainContent language={language} t={t} />
                          <Suspense fallback={<div className="h-96 flex items-center justify-center">Loading...</div>}>
                            <About language={language} />
                            <Prices language={language} />
                            <Testimonials language={language} />
                          </Suspense>
                        </div>
                      </>
                    }
                  />
                  <Route
                    path="/:lang/blog"
                    element={
                      <Suspense fallback={<div className="h-96 flex items-center justify-center">Loading...</div>}>
                        <BlogList language={language} />
                      </Suspense>
                    }
                  />
                  <Route
                    path="/:lang/blog/:slug"
                    element={
                      <Suspense fallback={<div className="h-96 flex items-center justify-center">Loading...</div>}>
                        <BlogPost language={language} />
                      </Suspense>
                    }
                  />

                  {/* Catch-all redirect */}
                  <Route
                    path="*"
                    element={<Navigate to={`/${getDefaultLanguageCode()}`} replace />}
                  />
                </Routes>
              </div>
            </div>
          </LanguageRouter>
        </Router>
        {isTawkToVisible && (
          <Suspense fallback={null}>
            <TawkTo />
          </Suspense>
        )}
      </div>
    </HelmetProvider>
  );
};

export default App;