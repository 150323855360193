// Language translations for the language selector
export const languageTranslations = {
    georgian: {
        georgian: 'ქართული',
        english: 'ინგლისური',
        russian: 'რუსული',
        german: 'გერმანული',
        french: 'ფრანგული',
        spanish: 'ესპანური',
        italian: 'იტალიური',
        latvian: 'ლატვიური',
        slovak: 'სლოვაკური'
    },
    english: {
        georgian: 'Georgian',
        english: 'English',
        russian: 'Russian',
        german: 'German',
        french: 'French',
        spanish: 'Spanish',
        italian: 'Italian',
        latvian: 'Latvian',
        slovak: 'Slovak'
    },
    russian: {
        georgian: 'Грузинский',
        english: 'Английский',
        russian: 'Русский',
        german: 'Немецкий',
        french: 'Французский',
        spanish: 'Испанский',
        italian: 'Итальянский',
        latvian: 'Латышский',
        slovak: 'Словацкий'
    }
};

// Available languages
export const languages = [
    'georgian',
    'english',
    'russian',
    'german',
    'french',
    'spanish',
    'italian',
    'latvian',
    'slovak'
];

// Language pricing
export const languagePrices = {
    georgian: 15,
    english: 15,
    russian: 15,
    german: 20,
    french: 25,
    spanish: 25,
    italian: 25,
    latvian: 25,
    slovak: 25
};

// Calculate notary price based on page count
export const calculateNotaryPrice = (pages) => {
    if (pages === 1) return 6;
    if (pages <= 10) return pages * 4;
    if (pages <= 50) return pages * 3;
    return pages * 2;
};

// Calculate delivery time based on page count and notary approval
export const calculateDeliveryTime = (pageCount, notaryApproval, language) => {
    let time = '';
    if (pageCount <= 10) {
        time = language === 'georgian' ? 'დაახლოებით 90 წუთი' : 'About 90 minutes';
    } else if (pageCount <= 40) {
        time = language === 'georgian' ? 'დაახლოებით 180 წუთი' : 'About 180 minutes';
    } else {
        time = language === 'georgian' ? 'ინდივიდუალური, ჩვენ დაგიკავშირდებით' : 'Custom, we will contact you';
    }

    if (notaryApproval) {
        if (time.includes('წუთი') || time.includes('minutes')) {
            time += language === 'georgian' ? ' + 1 დღე სანოტარო დამოწმებისთვის' : ' + 1 day for notary approval';
        } else {
            time = language === 'georgian' ? 'ინდივიდუალური, ჩვენ დაგიკავშირდებით' : 'Custom, we will contact you';
        }
    }

    return time;
};

// Copy text to clipboard with alert
export const copyToClipboard = (text, successMessage) => {
    navigator.clipboard.writeText(text).then(
        () => alert(successMessage),
        (err) => console.error('Error copying: ', err)
    );
};