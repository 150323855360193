import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { Menu, X, Globe, Phone, ChevronDown } from 'lucide-react';
import { english } from '../translations/english';
import { georgian } from '../translations/georgian';
import { russian } from '../translations/russian';
import { copyToClipboard } from '../utils/helpers';

const LanguageSelector = ({ language, setLanguage }) => {
    const languages = [
        { code: 'english', label: 'EN', fullLabel: 'English' },
        { code: 'georgian', label: 'KA', fullLabel: 'ქართული' },
        { code: 'russian', label: 'RU', fullLabel: 'Русский' }
    ];

    return (
        <div className="relative inline-block text-left">
            <select
                value={language}
                onChange={(e) => setLanguage(e.target.value)}
                className="appearance-none bg-indigo-600 text-white px-3 py-2 pr-8 w-[70px] rounded-md cursor-pointer hover:bg-indigo-700 transition-colors focus:outline-none focus:ring-2 focus:ring-indigo-500"
            >
                {languages.map((lang) => (
                    <option key={lang.code} value={lang.code} className="bg-white text-gray-900">
                        {lang.label}
                    </option>
                ))}
            </select>
            <ChevronDown className="absolute right-2 top-1/2 transform -translate-y-1/2 w-4 h-4 text-white pointer-events-none" />
        </div>
    );
};

const Header = ({ language, setLanguage }) => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isMobile, setIsMobile] = useState(false);
    const location = useLocation();
    const navigate = useNavigate();
    const t = language === 'english' ? english :
        language === 'georgian' ? georgian : russian;

    useEffect(() => {
        const checkIfMobile = () => {
            setIsMobile(window.innerWidth < 768);
        };
        checkIfMobile();
        window.addEventListener('resize', checkIfMobile);
        return () => window.removeEventListener('resize', checkIfMobile);
    }, []);

    const getLanguageCode = (lang) => {
        return lang === 'english' ? 'en' :
               lang === 'georgian' ? 'ka' : 'ru';
    };

    const scrollToSection = (sectionId) => {
        const element = document.getElementById(sectionId);
        if (element) {
            const header = document.querySelector('header');
            const headerHeight = header ? header.offsetHeight : 80;
            const elementPosition = element.getBoundingClientRect().top;
            const offsetPosition = elementPosition + window.pageYOffset - headerHeight;
            window.scrollTo({
                top: offsetPosition,
                behavior: 'smooth'
            });
        }
    };

    const handlePhoneClick = () => {
        if (isMobile) {
            window.location.href = 'tel:579737737';
        } else {
            copyToClipboard(
                '579737737',
                language === 'english' ? 'Number copied' :
                    language === 'georgian' ? 'ნომერი დაკოპირებულია' :
                        'Номер скопирован'
            );
        }
    };

    const navigationItems = [
        { key: 'home', section: 'main' },
        { key: 'about', section: 'about' },
        { key: 'prices', section: 'prices' },
        { key: 'testimonials', section: 'testimonials' },
        { key: 'blog', section: null }
    ];

    const handleNavClick = (e, item) => {
        e.preventDefault();
        setIsMenuOpen(false);

        const langCode = getLanguageCode(language);

        if (item.key === 'blog') {
            navigate(`/${langCode}/blog`);
            return;
        }

        if (location.pathname !== `/${langCode}`) {
            navigate(`/${langCode}`);
            setTimeout(() => {
                scrollToSection(item.section);
            }, 100);
            return;
        }

        scrollToSection(item.section);
    };

    return (
        <div className="fixed w-full top-11 md:top-9 z-40">
            <header className="bg-white shadow-sm">
                <div className="max-w-7xl mx-auto">
                    <div className="flex items-center justify-between px-4 py-3 sm:px-6 lg:px-8">
                        {/* Logo */}
                        <div className="flex-shrink-0">
                            <Link
                                to={`/${getLanguageCode(language)}`}
                                className="flex items-center text-indigo-600"
                                onClick={() => setIsMenuOpen(false)}
                            >
                                <Globe className="h-6 w-6 mr-2" />
                                <span className="text-xl font-bold hidden sm:inline">NotaryTranslation</span>
                            </Link>
                        </div>

                        {/* Desktop Navigation */}
                        <nav className="hidden md:flex items-center space-x-8">
                            {navigationItems.map((item) => (
                                <button
                                    key={item.key}
                                    onClick={(e) => handleNavClick(e, item)}
                                    className="text-gray-600 hover:text-gray-900 transition-colors duration-200"
                                >
                                    {t[item.key]}
                                </button>
                            ))}
                        </nav>

                        {/* Right side buttons */}
                        <div className="flex items-center space-x-4">
                            <button
                                onClick={handlePhoneClick}
                                className="hidden md:flex items-center px-4 py-2 text-white bg-indigo-600 rounded-md hover:bg-indigo-700 transition duration-200"
                            >
                                <Phone className="h-5 w-5 mr-2" />
                                <span>579 737 737</span>
                            </button>

                            <LanguageSelector language={language} setLanguage={setLanguage} />

                            <button
                                onClick={() => setIsMenuOpen(!isMenuOpen)}
                                className="md:hidden p-2 rounded-md text-gray-600 hover:text-gray-900 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
                            >
                                {isMenuOpen ? (
                                    <X className="h-6 w-6" />
                                ) : (
                                    <Menu className="h-6 w-6" />
                                )}
                            </button>
                        </div>
                    </div>
                </div>

                {/* Mobile menu */}
                <AnimatePresence>
                    {isMenuOpen && (
                        <motion.div
                            initial={{ opacity: 0, height: 0 }}
                            animate={{ opacity: 1, height: 'auto' }}
                            exit={{ opacity: 0, height: 0 }}
                            transition={{ duration: 0.2 }}
                            className="md:hidden bg-white border-t border-gray-200"
                        >
                            <div className="px-4 pt-2 pb-3 space-y-1">
                                {navigationItems.map((item) => (
                                    <button
                                        key={item.key}
                                        onClick={(e) => handleNavClick(e, item)}
                                        className="block w-full text-left px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50"
                                    >
                                        {t[item.key]}
                                    </button>
                                ))}
                            </div>
                        </motion.div>
                    )}
                </AnimatePresence>
            </header>

            {/* Mobile phone banner */}
            {isMobile && (
                <div className="bg-indigo-600 text-white text-center py-2">
                    <a href="tel:579737737" className="flex items-center justify-center">
                        <Phone className="h-5 w-5 mr-2" />
                        <span>579 737 737</span>
                    </a>
                </div>
            )}

            {/* Spacer for fixed header */}
            <div className="h-16 md:h-20" />
        </div>
    );
};

export default Header;