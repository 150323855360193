// LanguageRouter.js
import { useEffect } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';

const LanguageRouter = ({ children, language, setLanguage }) => {
  const navigate = useNavigate();
  const { lang } = useParams();
  const location = useLocation();

  const languageMap = {
    'en': 'english',
    'ka': 'georgian',
    'ru': 'russian'
  };

  const reverseLanguageMap = {
    'english': 'en',
    'georgian': 'ka',
    'russian': 'ru'
  };

  useEffect(() => {
    if (lang) {
      const mappedLanguage = languageMap[lang];
      if (mappedLanguage && mappedLanguage !== language) {
        setLanguage(mappedLanguage);
      }
    } else if (location.pathname === '/') {
      const languageCode = reverseLanguageMap[language];
      navigate(`/${languageCode}`, { replace: true });
    }
  }, [lang, language, location.pathname, navigate, setLanguage]);

  return children;
};

export default LanguageRouter;