import React from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { Helmet } from 'react-helmet-async';
import PriceCalculator from './PriceCalculator';

const MainContent = ({ language, t }) => {
    const getMetaDescription = () => {
        switch(language) {
            case 'english':
                return 'Professional notary translation services in Tbilisi. Fast, certified translations in English, Georgian, Russian, and other languages. Get your documents translated within 90 minutes.';
            case 'georgian':
                return 'პროფესიონალური ნოტარიული თარგმნის სერვისი თბილისში. სწრაფი, დამოწმებული თარგმანები ინგლისურ, ქართულ, რუსულ და სხვა ენებზე. მიიღეთ თქვენი დოკუმენტების თარგმანი 90 წუთში.';
            default:
                return 'Профессиональные услуги нотариального перевода в Тбилиси. Быстрые, заверенные переводы на английский, грузинский, русский и другие языки. Получите перевод ваших документов за 90 минут.';
        }
    };

    const getKeywords = () => {
        switch(language) {
            case 'english':
                return 'notary translation, certified translation, document translation, fast translation, Tbilisi translation services, legal translation, notarized translation';
            case 'georgian':
                return 'ნოტარიული თარგმანი, დამოწმებული თარგმანი, დოკუმენტების თარგმანი, სწრაფი თარგმანი, თარგმნის სერვისი თბილისში, იურიდიული თარგმანი';
            default:
                return 'нотариальный перевод, заверенный перевод, перевод документов, срочный перевод, услуги перевода в Тбилиси, юридический перевод';
        }
    };

    return (
        <>
            <Helmet>
                <title>
                    {language === 'english' 
                        ? 'NotaryTranslation - Professional Translation Services in Tbilisi' 
                        : language === 'georgian'
                        ? 'NotaryTranslation - პროფესიონალური თარგმნის სერვისი თბილისში'
                        : 'NotaryTranslation - Профессиональные услуги перевода в Тбилиси'}
                </title>
                <meta name="description" content={getMetaDescription()} />
                <meta name="keywords" content={getKeywords()} />
                <link rel="canonical" href="https://notarytranslation.ge" />
                <meta property="og:type" content="website" />
                <meta property="og:title" content="NotaryTranslation" />
                <meta property="og:description" content={getMetaDescription()} />
                <meta property="og:url" content="https://notarytranslation.ge" />
            </Helmet>

            <main>
                <div className="py-6">
                    <motion.section
                        id="main"
                        className="mb-12 bg-white bg-opacity-90 p-8 rounded-lg shadow-md mt-0"
                        initial={{ opacity: 0, y: 50 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.5 }}
                    >
                        {/* Primary H1 heading - most important for SEO */}
                        <h1 className="text-4xl font-extrabold text-gray-900 mb-6">
                            {language === 'english' 
                                ? 'Professional Translation Services' 
                                : language === 'georgian'
                                ? 'პროფესიონალური თარგმნის სერვისი'
                                : 'Профессиональные услуги перевода'}
                        </h1>

                        {/* Service categories with H2 headings */}
                        <div className="mb-8">
                            {/* <h2 className="text-2xl font-bold text-gray-800 mb-4">
                                {language === 'english' 
                                    ? 'Our Translation Services' 
                                    : language === 'georgian'
                                    ? 'ჩვენი სერვისები'
                                    : 'Наши услуги'}
                            </h2> */}
                            
                            <div className="grid md:grid-cols-2 gap-6">
                                <Link to={`/${language === 'english' ? 'en' : language === 'georgian' ? 'ka' : 'ru'}/notary-translation`} className="p-4 border rounded-lg hover:shadow-md transition-shadow">
                                    <h3 className="text-xl font-semibold text-indigo-600 mb-2">
                                        {language === 'english' 
                                            ? 'Notarized Translation' 
                                            : language === 'georgian'
                                            ? 'ნოტარიული თარგმანი'
                                            : 'Нотариальный перевод'}
                                    </h3>
                                    <p className="text-gray-600">
                                        {language === 'english'
                                            ? 'Official certified translations with notary approval'
                                            : language === 'georgian'
                                            ? 'ოფიციალური დამოწმებული თარგმანები ნოტარიული დამოწმებით'
                                            : 'Официальные заверенные переводы с нотариальным заверением'}
                                    </p>
                                </Link>

                                <Link to={`/${language === 'english' ? 'en' : language === 'georgian' ? 'ka' : 'ru'}/express-translation`} className="p-4 border rounded-lg hover:shadow-md transition-shadow">
                                    <h3 className="text-xl font-semibold text-indigo-600 mb-2">
                                        {language === 'english'
                                            ? 'Express Translation'
                                            : language === 'georgian'
                                            ? 'სწრაფი თარგმანი'
                                            : 'Срочный перевод'}
                                    </h3>
                                    <p className="text-gray-600">
                                        {language === 'english'
                                            ? 'Fast translation service within 90 minutes'
                                            : language === 'georgian'
                                            ? 'სწრაფი თარგმანის სერვისი 90 წუთში'
                                            : 'Быстрый перевод в течение 90 минут'}
                                    </p>
                                </Link>
                            </div>
                        </div>

                        {/* Email instructions with h2 heading */}
                        <h2 className="text-2xl font-bold text-gray-800 mb-4">
                            {language === 'english' 
                                ? 'Calculate Translation Cost' 
                                : language === 'georgian'
                                ? 'გამოთვალეთ თარგმანის ღირებულება'
                                : 'Рассчитать стоимость перевода'}
                        </h2>

                        <p className="mb-6 text-xl text-gray-700">
                            {t.emailInstruction} <strong>notarytranslation24@gmail.com</strong>{' '}
                            {t.receiveTranslation} <strong>90</strong> {t.minutes}
                        </p>

                        {/* Price Calculator */}
                        <div id="calculator" className="bg-white p-6 rounded-lg shadow-sm">
                            <PriceCalculator language={language} />
                        </div>
                    </motion.section>
                </div>
            </main>
        </>
    );
};

export default MainContent;