export const russian = {
    // Header
    home: "Главная",
    about: "О нас",
    prices: "Цены",
    testimonials: "Отзывы",
    blog: "Блог",

    // Main section
    mainTitle: "Профессиональные переводческие услуги",
    emailInstruction: "Отправьте нам ваш документ на",
    receiveTranslation: "и получите перевод через",
    minutes: "минут",

    // Price Calculator
    sourceLanguage: "Исходный язык",
    targetLanguage: "Целевой язык",
    pageCount: "Количество страниц",
    notaryApproval: "Нотариальное заверение",
    calculate: "Рассчитать",
    translationDetails: "Детали перевода",
    pricePerPage: "Цена за страницу",
    discount: "Скидка",
    translationCost: "Стоимость перевода",
    notaryCost: "Стоимость заверения",
    totalPrice: "Итоговая цена",
    estimatedDeliveryTime: "Приблизительное время доставки",
    copyEmail: "Скопировать email",
    copyPhone: "Скопировать номер телефона",

    // About section
    aboutTitle: "О нас",
    companyInfo: "Информация о компании",
    detailedInfo: "Подробная информация о наших услугах",
    foundationYear: "Год основания",
    translatorCount: "Количество переводчиков",
    languages: "Языки",
    specialization: "Специализация",
    specializationDetails: "Юридические, технические, медицинские и литературные переводы",

    // Prices section
    pricesTitle: "Цены и условия",
    pricingPolicy: "Ценовая политика",
    pricingDetails: "Подробная информация о наших ценах",
    standardPrice: "Стандартная цена",
    rareLanguages: "Редкие языки",
    notaryApprovalPrices: "Нотариальное заверение",
    discounts: "Скидки",

    // Testimonials section
    testimonialsTitle: "Отзывы клиентов",
    happyClients: "Отзывы довольных клиентов",
    clientOpinions: "Что говорят о нас наши клиенты",

    // Footer
    company: "Компания",
    contact: "Контакты",
    services: "Услуги",
    documentTranslation: "Перевод документов",
    notaryService: "Нотариальные услуги",
    expressTranslation: "Срочный перевод",
    getInTouch: "Связаться с нами",
    allRightsReserved: "Все права защищены",

    // Blog
    readMore: "Подробнее",
    readingTime: "мин чтения"
};